@import '../../../App.scss';

.servicesPage {
    @include flexbox-spaceBtwn;
    flex-wrap: wrap;
    width: 100%;
    .servicesItem {
      margin: 10px;
      cursor: pointer;
      background-color: rgb(243, 241, 241);
      width: calc(25% - 20px);
      overflow: hidden;
      box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.18);
      @include breakpoint ('small'){
        width:100%;
      }
      @include breakpoint ('medium'){
        width: calc(50% - 20px);
      }
      &:hover svg{
        transform: translateX(10px);
      }
      img {
        width: 100%;
        height: 250px;
        transition: transform 0.3s ease;
        object-fit: cover;
        &:hover{
            transform: scale(1.1);
        }
        
      }
      .txtPan{
        @include flexbox-jxtStart;
        padding: 0 10px;
        
        svg {
          color: rgb(107 107 107 / 56%);
          transition: transform 0.3s ease;
        }
        div {
          display: flex;
        }
      }
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin: 0 auto;
    z-index: 1000;
    

    .modal-content {
      background: #eaeaea;
      padding: 100px 30px 0;
      text-align: center;
      position: fixed;
      width: 100%;
      height: 100vh;
      overflow: scroll;
      .close {
        position: absolute;
        top: 120px;
        right: 50px;
        font-size: 30px;
        cursor: pointer;
        @include breakpoint('small') {
          top: 90px;
          right: 15px;
        }
      }
  .modal-contentInner{
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap:3em;
    overflow-y: auto;
    height: calc(100vh - 170px);
    
      .picPan {
        width: 35%; /* Adjust the width as needed */
        img {
          width: 100%;
          height: auto;
        }
      }
      .textPan{
        width: 60%;
        p {
          text-align: justify;
      }
      h2 {
        text-align: start;
        margin-top: 0;
        @include breakpoint ('small'){
          margin-top: 30px;
        }
    }
      }
      @include breakpoint ('small'){
        display: block;
        padding: 20px 0 0;
        height: calc(100vh - 170px);
        .picPan,.textPan{
          width: 100%;
        }
  
      }
    }

      
    }
  }
}