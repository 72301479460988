@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '../../../../App.scss';

.gallery {
  text-align: center;

  #headTitle {
    margin-bottom: 1rem;
    text-align: left;
    
    @include breakpoint('from960') {
      max-width: calc(100% - 20px);
    }
    #title1{
      text-transform: initial;
    }
  }

  .content {
    padding-bottom: 10px;

    @include breakpoint('small') {
      padding-bottom: 20px;
    }

    .image-gallery {
      a {
        text-decoration: none;
      }

      .image {
        position: relative;
        background-size: cover;
        background-position: center;
        height: 200px;
        margin: 0 15px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: transform 0.3s ease, box-shadow 0.3s ease;

        @include breakpoint('small') {
          margin: auto;
          width: auto;
        }

        @include breakpoint('medium') {
          margin: 0 15px;
          height: 200px;
          width: calc(100% - 30px);
        }

        @include breakpoint('large') {
          margin: 10px;
          height: 200px;
          width: calc(100% - 20px);
        }

        &:hover {
          transform: scale(0.9);
          box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
        }

        .overlay {
          @include positions(0, 0, 0, 0);
          background-color: rgba(0, 0, 0, 0.34);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: background-color 0.3s ease;
          svg {
            height: 30px;
            margin: 5px 0 0 -10px;
            path {
              stroke: white;
            }
          }
        }

        .image-title {
          color: white;
          font-size: 17px;
          text-align: center;
          z-index: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 200px;
          transition: color 0.3s ease;
        }

        &:hover .overlay {
          background-color: rgba(0, 0, 0, 0.5);
        }

        &:hover .image-title {
          color: $secondary-clr;
        }
      }
      .slick-dots {
        bottom: -20% !important;
      }

    }
  }

  .cta {
    text-align: right;
    margin: 0 10px;

    @include breakpoint('small') {
      text-align: left;
      margin: 0;
    }
  }
}
