@import './../../App.scss';

header {
  position: fixed;
  top: 0;
  z-index: 1001;
  width: 100%;
  padding: 0px 20px;
  transition: all 0.3s ease;

  img.logo {
    width: 80px;
    height: auto;
    margin: 10px 0 0px 30px;
    transform: scale(2.3);
    transition: all ease 0.3s;
    @include breakpoint('large') {
      width: 100px;
      margin: 10px 0 0px 50px;
    }
  }
  svg {
    width: 100%;
    height: auto;
    transform: scale(1.5);
    transition: all ease 0.3s;
  }
  .logoText1,
  .logoText2 {
    fill: #ffffff;
  }
  &.white-bg {
    border-bottom: 1px solid #e7e7e7;
    img.logo {
      transform: scale(2);
      @include breakpoint('large') {
        transform: scale(1.8);
      }
    }
    .logoText1 {
      fill: #396873;
    }
    .logoText2 {
      fill: #787878;
    }
    .navMenu .nav-links a {
      color: #333;
    }
    svg {
      transform: scale(1.2);
      margin-top: -15px;
      @include breakpoint('small') {
        transform: scale(1.2);
        margin-top: -16px;
        margin-left: -30px;
      }
    }
    .navbar-logo {
      a {
        div {
          height: 103px;
          width: 220px;
          @include breakpoint('small') {
            height: 85px;
            width: 200px;
          }
        }
      }
    }
    .menu-icon {
      opacity: 1;
    }
  }
  .menu-icon {
    opacity: 0;
  }
  .navbar {
    width: 100%;
    @include flexbox-spaceBtwn;
  }
  .navbar-logo {
    a {
      display: block;

      div {
        height: 130px;
        width: 300px;
        overflow: hidden;
        @include breakpoint('small') {
          height: auto;
          width: 100%;
        }
      }
    }
  }

  .navMenu {
    @include flexbox-spaceBtwn;
    list-style: none;
    margin: 0;
    padding: 0;
    justify-content: center;
    gap: 2.5em;
    @include breakpoint('large') {
      gap: 1em;
    }

    li {
      margin: 0 10px;
    }
    .nav-links a {
      position: relative;
      color: #000;
      padding: 10px 15px;
      font-weight: 600;
      text-decoration: none;
      font-size: 20px;
      @include breakpoint('from960') {
        color: #fffc;
      }

      &:after,
      li .active::after {
        background: none repeat scroll 0 0 transparent;
        bottom: 0px;
        content: "";
        color: $teritiary-clr;
        height: 2px;
        position: absolute;
        background: $secondary-clr;
        transition: width 0.2s ease, left 0.2s ease;
        width: 0;
      }

      &:hover:after,
      &.active:after {
        width: 100%;
        left: 0;
      }

      &.active::after {
        background: $primary-clr;
      }
    }
  }
}

@media (max-width: 960px) {
  .i-close {
    position: absolute;
    top: 20px;
    right: 5px;
    width: 40px;
    height: 40px;
    text-align: center;
    transition: all 0.3s ease-out;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  .i-barger.i-close {
    transform: scale(0.8) rotateZ(0deg);
    background-image: url(../../../public/icons/i-burger.svg);
  }

  .i-cross.i-close {
    transform: scale(0.8) rotateZ(0deg);
    background-image: url(../../../public/icons/i-close.svg);
  }


  .navMenu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.3s ease;
    justify-content: flex-start;
  }

  .navMenu.active {
    position: absolute;
    left: 0;
    opacity: 1;
    z-index: -1;
    top: 0;
    padding: 170px 10%;
    margin: 0;
    background: rgb(234,244,246);
background: linear-gradient(42deg, rgba(234,244,246,1) 0%, rgba(205,205,205,1) 100%);
  }

  .navMenu li {
    margin-left: 100px;
    opacity: 0;
    transition: all 1.5s ease;
  }

  .navMenu.active li {
    margin-left: 0;
    opacity: 1;
  }

  .nav-links a {
    text-align: center;
    padding: 2rem 1rem 1rem;
    width: 100%;
    display: table;
    color: #fffc;
    font-weight: 500;
    font-size: 1.6em;
  }
}