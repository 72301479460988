@import "../../../../App.scss";

.AboutSec2 {
    padding: 0;
}

.featuresSection {
    width: 100%;
    padding: 0;
    margin: 0;
  .featuresSectionRepeat {
    @include flexbox-center;
    width: 100%;
    margin: 0 auto;
    padding: 60px;
    @include breakpoint("small") {
      padding: 30px;
      text-align: left;
      align-items: start;
    }
    &:nth-child(even) {
      flex-direction: row-reverse;

      .col-2 {
        padding-right: 50px;
      }
      @include breakpoint("small") {
        flex-direction: column;

        .col-2 {
          padding-right: 0;
        }
      }
    }
    &:first-child {
      ul,
      p {
        font-size: 1.25em;
        @include breakpoint("large") {
          font-size: 1.1em;
        }
      }
    }
    h2 {
        font-size: 2.2rem;
    }
    @include breakpoint("small") {
      h2 {
        font-size: 1.6rem;
      }
    }

    &:nth-child(odd) {
      flex-direction: row;
      background: $light-bg1;

      .col-2 {
        padding-left: 20px;
        @include breakpoint("small") {
          padding-left: 0;
        }
      }
      @include breakpoint("small") {
        flex-direction: column;
      }
    }
    img {
      height: 120px;
      @include breakpoint("small") {
        height: 80px;
      }
    }
  }
}
