@import "./../../../../App.scss";

.aboutSection {
  width: 100%;
  @include breakpoint("large") {
    padding: 2% 4%;
    // flex-direction: row;
    
  }
  #headTitle{
    @include breakpoint("large") {
      margin: 0 3em 3em 0 ;
      max-width: 90%;
    }
  }
  .aboutSectionInner{
  width: 100%;
  // display: flex;
  // justify-content: space-between;
  display: block;
  padding: 0;
  text-align: start;
 
  .txtPan {
    width: 100%;
    @include breakpoint("large") {
      width: 55%;
    }

    svg path {
      stroke: none;
    }
    .features {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin: 15px 0;

      .feature-item {
        width: 50%;
        display: flex;
        justify-content: start;
        align-items: center;
       @include breakpoint ('small'){
        width: 100%;
       }
        svg {
          fill: $primary-clr;
          width: 35px;
          height: 35px;
          margin-right: 10px;
        }
      }
    }
    a,
    .feature-item p,
    .callUs p {
      color: black;
      font-size: 1.3rem;
      text-decoration: none
    }
    .callUs {
      margin: 30px  0 50px 0;
      @include flexbox-jxtStart;
      svg {
        background: $primary-clr;
        path {
          stroke: white;
        }
        width: 60px;
        height: 60px;
        padding: 15px;
        border-radius: 3px;
        margin-right: 10px;
      }
      .text 
      {
        p{
        margin: 0;
      }
    }
    }
    .btn {
        display: flex;
        flex-direction: row;
        text-align: center;
        gap: 1em;
        @include breakpoint("small") {
            flex-direction: column;
          }
      a {
        color: white;
        text-decoration: none;
        font-size: 20px;
        background: $primary-clr;
        padding: 15px 40px;
      }
    }
  }
  .txtPan > * {
    margin: 20px 0;
  }
  .picPan {
    width: 100%;
    @include breakpoint("large") {
        width: 40%;

    }

    img {
      width: 100%;
      height: 350px;
      object-fit: cover;
      @include breakpoint("medium") {
        width: 100%;
        height: 550px;
      }
      @include breakpoint("large") {
        width: 100%;
        height: 600px;
      }
    }
  }
   @include breakpoint("large") {
    @include flexbox-spaceBtwn;
  }

}

}

.about {
  @include breakpoint("small") {
    text-align: center;
    display: block;
    .txtPan {
      width: 100%;
      .features .feature-item {
        width: 100%;
        p {
          font-size: 16px;
          margin: 5px 0;
        }
      }
      .callUs {
        display: block;
        p {
          font-size: 18px;
          margin: 15px 0;
        }
        svg {
          width: 35px;
          height: 35px;
          padding: 7px;
        }
      }
      .btn {
        margin: 35px 0;
        a {
          font-size: 18px;
          padding: 10px 40px;
        }
      }
    }
    .picPan {
      width: 100%;
      img {
        width: 100%;
        height: auto;
      }
    }
  }
}
