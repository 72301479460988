@import './../../App.scss';
.scroll-to-top {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 999;
  }
  
  .scroll-button {
    background-color: $primary-bg;
    border: none;
    padding: 10px 20px;
    border-radius: 2px;
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-button:hover {
    background-color: $secondary-clr;
  }
  