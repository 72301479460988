@import '../../../App.scss';

.projectSection {
   width: 100%;
   padding-top: 110px;
   .title {
      text-align: center;
      max-width: 960px;
      margin: 0 auto;
      width: 100%;
      font-size: 1.4rem;
      font-weight: 800;
      color: $primary-clr;
      text-transform: uppercase;
      margin: 1.5em auto 0.5em !important;
   }

   .subheading {
      text-align: center;
      font-size: 3rem;
      font-weight: 600;
      text-transform: capitalize;
      @include breakpoint('small') {
         font-size: 1.8rem;
      }

    &::after {
      content: '';
      display: block;
      width: 10%;      
      height: 3px;      
      background-color: $secondary-clr;  
      margin: 0 auto;   
      margin-top: 5px; 
   }
}

   .projectColumn {
      padding: 20px;
      width: 100%;
      @include flexbox-spaceBtwn;
      flex-wrap: wrap;
      margin: 0 auto;
      align-items: flex-start;


      .picPan {
         width: calc(40% - 20px);

         @include breakpoint('upto800') {
            width: 100%;
         }

         img {
            width: 100%;
            height: auto;
            box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
         }
      }

      .textPan {
         width: calc(60% - 20px);

         @include breakpoint('upto800') {
            width: 100%;
         }
      }
   }

   hr {
      width: 95%;
      margin: 10px auto !important;
   }
}