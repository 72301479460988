// Define a mixin for breakpoints
@mixin breakpoint($point) {
  @if $point == 'small' {
    @media (max-width: 600px) {
      @content;
    }
  }@if $point == 'upto800' {
    @media (max-width: 800px) {
      @content;
    }
  } @else if $point == 'medium' {
    @media (min-width: 601px) and (max-width: 1024px) {
      @content;
    }
  } @else if $point == 'large' {
    @media (min-width: 1025px) {
      @content;
    }
  } @else if $point == 'morelarge' {
    @media (min-width: 1700px) {
      @content;
    }
  } @else if $point == 'from960' {
    @media (min-width: 960px) {
      @content;
    }
  }
}