@import '../../../../App.scss';
.projectMain{
  .contact {
    .bannerMain {
      display: none;
    }
    #headTitle {
      padding-top: 0;
      max-width: 740px;
    }
    background-color: #e3eafc;
    
    .contact__container {
      @include breakpoint('large') {
        flex-direction: row;
      }
    }
    .contact-info {
      padding: 40px;
      background: #396873;
      @include breakpoint('large') {
        padding: 60px;
      }
    }
    .contact-info .contact-item .txtPan {
      a, p {
        color: #fff;
      }
    }
    .contact-item svg {
      background-color: #004251;
    }
  }
}