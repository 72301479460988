$primary-clr: #386873;
$secondary-clr: #F2E74B;
$teritiary-clr: #253d43;

$primary-bg: #FFFBFE;
$secondary-bg: #D0CFCF;
$teritiary-bg: #7A7D7D;
$quaternary-bg: #595959;

$light-bg1: #e7f0f2;
