//flex in mixins
@mixin flexbox-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  @mixin flexbox-spaceBtwn {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @mixin flexbox-jxtStart{
    display: flex;
    align-items: center;
    justify-content: start;
  }

  @mixin positions($top, $right, $bottom, $left) {
    position: absolute;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
  }

  @mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
    transition: $property $duration $ease;
}