@import '../../../../App.scss';

.whyUs {
    text-align: center;
    // padding:2% 4%;
    background: rgb(235,235,235);
    background: linear-gradient(180deg, rgba(235,235,235,1) 0%, rgba(173,173,173,1) 100%);
    

    .features-info {
        width: 100%;
        display: flex;
        justify-content: space-between;
        text-align: start;

        .feature-item {
            padding: 20px 0;

            h3 {
                font-size: 23px;
                color: $primary-clr;
            }

            .icon svg {
                padding: 15px;
                width: 60px;
                height: 60px;
                background-color: $primary-clr;
                border-radius: 3px;
            }

            p {
                margin: -10px 0 25px;
                color: #333;
            }
        }

        .image{
            @include flexbox-center;
            padding: 0 20px;
            transform: scalex(1.1);
         img {
            width: 100%;
            height: auto;
        }
    }
    }
    .features-info>* {
        width: calc(33.3% - 20px)
    }

    @include breakpoint("small") {
        #headTitle {
          text-align: left;
        }

        padding:2% 7%;
        
        .features-info{
            display: block;
            & > *{
                width: 100%;
               
            }
            .col-3{
                    padding-top:25px
            }
            .feature-item{
                padding: 10px 0;
                h3{
                    font-size: 20px;
                }

            }
        }
    }
    @include breakpoint('large') {
        .whyUs .features-info .feature-item h3 {
            font-size: 30px;
          }
    }
}