.opacityF2T {
  animation: opacity 0.35s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-left {
  animation: slide-left 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-right {
	        animation: slide-right 0.6s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-fwd-top {
  animation: slide-fwd-top 0.45s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.slide-bottom {
	        animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}.slide-bottom-infinite {
  animation: slide-bottom-infinite 1.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) 2s infinite forwards;
 }
@keyframes slide-left {
  0% {
            transform: translateX(-100px);
  }
  100% {
            transform: translateX(0);
  }
}
@keyframes slide-right {
  0% {
            transform: translateX(100px);
  }
  100% {
            transform: translateX(0);
  }
}

@keyframes slide-fwd-top {
  0% {
            transform: translateZ(0) translateY(-100vh);
  }
  100% {
            transform: translateZ(160px) translateY(0);
  }
}

@keyframes slide-bottom {
  0% {
            transform: translateY(-50px);
  }
  100% {
            transform: translateY(0);
  }
}


@keyframes opacity {
  0% {
      opacity: 0;
  }
  100% {
      opacity: 1;
  }
}
@keyframes slide-bottom-infinite {
  0% {
  transform: translateY(0);
  }
  100% {
  transform: translateY(40px);
  }
 }