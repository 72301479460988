@import "../../App.scss";

.bannerMain {
  padding: 0;
}
.bannerInner {
  position: relative;
  padding: 0;
  overflow: hidden;
  width: 100%;
  margin: 0;
  display: flex;
  height: 360px;
  align-items: end;
  justify-content: end;
  @include breakpoint("large") {
    height: 370px;
  }
  @include breakpoint("morelarge") {
    height: 460px;
  }
  picture {
    width: 100%;
    height: 100%;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background:linear-gradient(42deg, rgba(57,104,115,0.1) 0%, rgba(57,104,115,1) 90%);
  }
  .textPanel {
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    font-size: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    z-index: 1;
    h1 {
      width: 100%;
      max-width: 500px;
      margin: 0;
      padding: 0 25px;
      font-size: 2.4rem;
      text-shadow: 2px -2px 0 rgba(0, 48, 59, 0.87), 1px -1px 0 rgba(0, 48, 59, 0.87), -1px 1px 0 #000, 1px 1px 0 rgba(0, 48, 59, 0.87);
      @include breakpoint("from960") {
        font-size: 3rem;
        padding-left: 3%;
      }
      @include breakpoint("large") {
        text-shadow: 4px -4px 0 rgba(0, 48, 59, 0.87), 1px -1px 0 rgba(0, 48, 59, 0.87), -1px 1px 0 #000, 1px 1px 0 rgba(0, 48, 59, 0.87);
        padding: 0;
        padding-left: 4%;
      }
      @include breakpoint("morelarge") {
        max-width: 1026px;
        margin: 0 auto;
      }
    }
    @include breakpoint("morelarge") {
      height: 460px;
    }
  }
}