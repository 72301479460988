@import '../../App.scss';

.footer {
    background-color: black;
    color: white;
    padding: 20px 0;
    .logo {
        display: block;
        margin: 20px 0 0;
        img{
            width: 80px;
            height: auto;
        }
    }
}

.cta-content {
    flex-direction: column;
    @include flexbox-center;
}


.footer-container {
    display: flex;
    flex-wrap: wrap;
    width: auto;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.footer-section {
    flex: 1;
    min-width: 250px;
    width: 94%;
    margin: 10px;
}

.footer-section h3 {
    border-bottom: 2px solid #5b56565e;
    padding-bottom: 15px;
    margin-bottom: 15px;
}

.footer-section p,
.footer-section ul {
    margin: 0;
    padding: 0;
}

.footer-section ul {
    list-style: none;
}

.footer-section ul li {
    margin: 5px 0;
}

.footer-section ul li a {
    color: #fff;
    text-decoration: none;
}

.footer-section ul li a:hover {
    text-decoration: underline;
}

.social-media svg {
    padding: 5px;
    margin: 2px;
}

.social-media a {
    display: inline-block;
    color: #fff;
    margin-right: 10px;
    text-decoration: none;
}

.social-media a:hover {
    text-decoration: underline;
}

.footer-bottom {
    text-align: center;
    padding: 10px;
    background-color: #222;
    border-top: 1px solid #555;
    margin-top: 20px;
    a,
    p {
        color: #a9a9a9;
        display: flex;
        text-align: center;
        justify-content: center;
    }
    a:hover {
        text-decoration: none;
    }
    p:first-child {
        margin-bottom: 5px;
    }
    p:last-child {
        font-size: smaller;
        padding: 0;
        margin: 0;
    }
}

@media (max-width: 768px) {
    .footer-container {
        flex-direction: column;
        align-items: center;
    }

    .footer-section {
        margin: 0;
    }

    .footer-section h3 {
        border-bottom: 2px solid rgba(91, 86, 86, 0.368627451);
        padding-bottom: 0;
        margin-bottom: 0;
    }
}