@import '../../../../App.scss';

.services {
    text-align: center;
    background-color: $primary-bg;
    
    .services-info {
        .services-info__item {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin: 20px 0;
            padding: 0;
            gap: 3em;
            @include breakpoint('large') {
                gap: 2em;
                h3.services-info__item__list__title {
                    font-size: 1.6em;
                    line-height: 1.25;
                    padding: 0 14%;
                    margin-top: 10px;
                    text-align: center;
                  }
            }
            @include breakpoint('morelarge') {
                gap: 3em;
            }
        }
        .services-info__item__list {
            display: flex;
            flex-direction: column;
            width: 100%;
            p {
                margin-top: 0;
            }
            @include breakpoint('large') {
                width: calc(50% - 4em);
            }
            @include breakpoint('morelarge') {
                width: calc(33.333% - 4em);
            }
        }
        .services-info__item__list.last {
            background-color: $primary-clr;
            color: #fff;
            padding: 1.6em;
            align-items: center;
            justify-content: center;
            @include breakpoint('large') {
                padding: 2em 1.6em;
                margin-top: 1em;
            }
            h3 {
                font-size: 1.52rem;
                text-transform: uppercase;
            }
            p {
                color: #fff;
            }
            a {
                color: #fff;
                text-decoration: none;
                font-family: "getvoip_grotesqueregular", sans-serif;
                font-size: 1.8rem;
            }
        }
    }
}