@import './App.scss';

.container {
  width: 100%;
  max-width: calc(1600px + 10%);
  margin: auto;
}

section {
  padding: 2% 4%;
}

#d-block {
  display: block;
}

.transparent-bg {
  background-color: transparent;
}

.white-bg {
  background-color: white;
}

.relative {
  position: relative;
}

.containerSpacing-1 {
  padding: 3em 0 1em;

  @include breakpoint('large') {
    padding: 3em 4% 1em;
  }
}

.containerSpacing-2 {
  padding: 3em 0 0;

  @include breakpoint('large') {
    padding: 3em 4% 1em;
  }
}

.containerSpacing-3 {
  padding: 3em 7%;
  border-top: 1px solid #e4e4e4;
}

.bannerInnerSmall,
.bannerInner {
  margin-top: 85px;

  @include breakpoint("large") {
    margin-top: 103px;
  }
}



.slick-dots {
  position: absolute !important;
  bottom: 40px !important;
  left: 0;
  display: flex !important;
  justify-content: center !important;
  gap: 3px;

  @include breakpoint ('small') {
    bottom: 5% !important;
  }

  @include breakpoint ('large') {
    bottom: 10%;
  }

  @include breakpoint ('extralarge') {
    bottom: 13%;
  }

  li {
    margin: 0 5px !important;
    padding: 0 !important;
    width: 15px !important;
    height: 15px !important;
    transition: all ease 0.3s;

    button {
      width: 100% !important;
      height: 100% !important;
      padding: 0 !important;
      border: none !important;
      background: #ccc !important;
      border-radius: 3px !important;


      &:before {
        display: none !important;
      }
    }

    &.slick-active {
      width: 30px !important;
    }

    &.slick-active button {
      background-color: $secondary-clr !important;
    }
  }
}

.slick-prev,
.slick-next {
  visibility: visible;
  opacity: 1;
  z-index: 1;
  &:before {
    font-size: 28px;
    font-weight: bold;
    color: $primary-clr;
    opacity: 1;
  }
}

.slick-prev {
  left: 0;
  @include breakpoint('from960') {
    left: -50px;
  }
}

.slick-next {
  right: 0;
  @include breakpoint('from960') {
    right: -50px;
  }
}

.slick-prev, .slick-next {
  width: 30px;
  height: 30px;
  right: 0;
  left: inherit;
  top: -26px;
}

.slick-prev {
  right: 35px;
}