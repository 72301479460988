@import "../../../App.scss";

.contact {
  background-color: $light-bg1;
  padding: 0px;
  min-height: 100vh;

  #headTitle {
    padding-top: 60px;
    text-align: center;
    max-width: 620px;
    @include breakpoint("small") {
      padding: 30px 7% 0;
    }
  }

  .contact__container {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 40px;
    @include breakpoint("large") {
      flex-direction: row-reverse;
      margin-bottom: 80px;
    }
    iframe {
      height: calc(100vh - 120px);
      box-shadow: 24px -25px 15px -15px rgba(0, 0, 0, 0.05);
      @include breakpoint("large") {
        height: calc(100vh - 100px);
      }
    }
  }

  .contact-info {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 3em;
    width: 100%;
    padding: 20px 40px;

    @include breakpoint("large") {
      width: 40%;
      padding-top: 40px;
    }

    .contact-item {
      @include flexbox-spaceBtwn;

      svg {
        width: 50px;
        height: 50px;
        padding: 15px;
        background-color: #386873;
        margin-right: 15px;
        border-radius: 5px;
      }

      .txtPan {
        text-align: start;
        font-size: 18px;
        font-weight: 700;

        p {
          margin: 5px 0;
        }

        a {
          color: $primary-clr;
          text-decoration: none;
        }
      }
    }
  }

  @include breakpoint("small") {
    
    section.contact {
      padding: 85px 0px;
    }
    h1 {
      font-size: 23px;
    }

    .contact-info {
      display: block;

      .contact-item {
        @include flexbox-jxtStart;
        margin: 20px 0;
        width: 100%;
      }
    }
  }

  @include breakpoint("medium") {
    .contact-info {
      margin: 0;

      .contact-item {
        display: block;

        svg {
          margin: 0;
        }

        .txtPan {
          text-align: center;
        }
      }
    }
  }
}
