@import './../../../../App.scss';
.logo-slider {
    text-align: center;
    #headTitle {
      #title1{
        text-transform: capitalize;
      }
    #title2{
      font-size: 18px;
    text-transform: none;
    color: black
    }
    }
    .logo-slide {
      @include flexbox-center;
      gap: 2em;
      img {
        max-width: 100%;
        width: auto;
        max-height: 100px;
        filter: grayscale(100%);
        transition: all ease 0.3s;
        &:hover {
          filter: grayscale(0);
        }
      }
    }
  }