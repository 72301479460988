#headTitle {
    text-transform: capitalize;
    max-width: 960px;
    margin: 0 auto;
    width: 100%;
    // padding: 20px 0;

    @include breakpoint('large') {
        margin: 0 auto 3em;
    }

    span {
        display: block !important;
    }

    #title1 {
        font-size: 1.4rem;
        font-weight: 800;
        color: $primary-clr;
        text-transform: uppercase;
        margin-bottom: 0.5em;

    }


    #title2 {
        font-size: 3rem;
        font-weight: 600;
        text-transform: capitalize;
        border-bottom: 1px #396873 solid;
        padding-bottom: 36px;

        @include breakpoint('small') {
            font-size: 1.8rem;
        }
    }

}
ul,
p {
    color: $teritiary-clr;
    line-height: 1.5;

    @include breakpoint('small') {
        font-size: 16px;
        letter-spacing: 0;
    }

    @include breakpoint('medium') {
        font-size: 16px;
        letter-spacing: 0.01em;
    }

    @include breakpoint('large') {
        font-size: 20px;
        letter-spacing: 0.01em;
    }
}

ul {
    list-style: square;
    line-height: 1.7 !important;
}