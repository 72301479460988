@import './variables.scss';
@import './mixins.scss';
// //btn color in mixin
// @mixin CustomBtn($btnclr1, $btnclr2) {
//     border: 1px solid $btnclr2;
//     border-radius: 2px;
//     background-color: $btnclr1;
//     color: $btnclr2;
//     padding: 10px;
//     &:hover {
//       background-color: $btnclr2;
//       color: $btnclr1;
//     }
//   }

//   // 
// .cta {
//     @include CustomBtn($primary-bg, $primary-clr);
//     display: inline-block;
//     transition: 0.3s ease;


//     .btnSideIcon svg {
//       display: inline-block;
//       height: 16px;
//       vertical-align: middle;
//       margin-left: 5px;

//     }

//     &:hover .btnSideIcon svg {
//       transform: translateX(5px);
//       fill: #fff;

//     }
//   }



@import url('https://fonts.googleapis.com/css?family=Mukta:700');

.cta a {
    position: relative;
    display: inline-block;
    cursor: pointer;
    outline: none;
    border: 0;
    vertical-align: middle;
    background: transparent;
    padding: 0;
    font-size: inherit;
    font-family: inherit;

    &.learn-more {
        width: 9rem;
        height: auto;

        .circle {
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
            position: relative;
            display: block;
            margin: 0;
            width: 2rem;
            height: 2rem;
            background: $primary-clr;
            border-radius: 26px;

            .icon {
                @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                @include positions(0, auto, 0, auto);
                margin: auto;
                background: #fff;

                &.arrow {
                    @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
                    transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
                    left: 0.2rem;
                    width: 1.125rem;
                    height: 0.125rem;
                    background: none;

                    &::before {
                        @include positions(-0.25rem, 0.0625rem, auto, auto);
                        content: '';
                        width: 0.625rem;
                        height: 0.625rem;
                        border-top: 0.125rem solid #fff;
                        border-right: 0.125rem solid #fff;
                        transform: rotate(45deg);
                    }
                }
            }
        }

        .btnTxt {
            @include flexbox-center;
            @include positions(0, 0, 0, 0 );
            @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
            transition: all 0.45s cubic-bezier(0.65, 0, 0.076, 1);
            margin: 0 0 0 1.85rem;
            color: $primary-clr;
            font-weight: 700;
            text-align: center;
            text-decoration: underline;
        }
    }

    &:hover {
        .circle {
            width: 100%;

            .icon {
                &.arrow {
                    background: #fff;
                    transform: translate(1rem, 0);
                }
            }
        }

        .btnTxt {
            color: #fff;
            text-decoration: none;
        }
    }
}