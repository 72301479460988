@import '../../styles/mixins.scss';
@import '../../styles/variables.scss';
.thisNone {
    display: none;
}
.scrollNext {
    position: fixed;
    bottom: 100px;
    left: 100px;
    z-index: 1003;
}
.chkScrollEl {
    overflow: hidden;
    max-height: 100%;
    width: 100%;
    scroll-behavior: smooth;
    counter-reset: section -1;
}
.heroBanner{
    width: 100%;

    video {
        width: 100%;
        height: 80vh;
        position: relative;
        object-fit:cover;
    }
    .textPan{
        position: absolute;
        text-align: center;
        text-shadow: 2px -2px 0 rgba(0, 48, 59, 0.87), 1px -1px 0 rgba(0, 48, 59, 0.87), -1px 1px 0 #000, 1px 1px 0 rgba(0, 48, 59, 0.87);
        @include breakpoint('large') {
            text-shadow: 4px -4px 0 rgba(0, 48, 59, 0.87), 1px -1px 0 rgba(0, 48, 59, 0.87), -1px 1px 0 #000, 1px 1px 0 rgba(0, 48, 59, 0.87);
        }
        .text {
            font-size: 23px;
            color: #fff;
            .colorTxt {
                color:$secondary-clr;
            }
        }
        .cta{
            margin-top: 65px;
            span.btnTxt {
                color:#fff !important ;
                font-size: 20px;
            }
            :hover {
                span.btnTxt {
                    color:#fff !important ;
                } 
            }
        }
        
    }
    .video:after {
        @include positions(0,0,auto,auto);
        // position: absolute;
        // top: 0;
        content: '';
        // left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(42deg, rgba(57,104,115,0.1) 0%, rgba(57,104,115,1) 90%);
        display: inline-block;
    }

    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .video,
    video {
        width: 100%;
        height: 100vh;
        position: relative;
        object-fit: cover;
    }
    
    .textPan {
        height: auto;
        @include breakpoint('small') {
            width: 90%;
        }
    }
    h1 {
        font-size: 2.6em;
        @include breakpoint('small') {
            font-size: 1.7em;
            opacity: 0.84;
        }
        span {
            display: inline-block;
        }
    }
    #d-block {
        font-size: 1.005em;
        padding: 10px 0 0;
        margin-top: 20px;
        border-top: 1px solid #fff;
    }
    .scrollToAbout{
        position: absolute;
        bottom: 60px;
        left: calc(50% - 25px);
        opacity: 0.6;
        z-index: 1000;
        transition: all ease 0.3s;
        cursor: pointer;
        &:hover {
            opacity: 1;
        }
    }
} 


